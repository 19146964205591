$main-bg: #4b7d13;
$bg-2: #97bf58;
$bg-3: #cb0e0e;
$bg-4: #245d3c;
$bg-5: #ba8953;
$bg-6: #ffb902;
$bg-7: #e2f7e7;

@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");

* {
  font-family: "Mulish", sans-serif;
}

body {
  background: #f4f4f4 !important;
}

a {
  text-decoration: none !important;
  // color: black !important;
}

.nav-tabs .nav-link {
  color: black !important;
}

.bg-or {
  background: #ffb902 !important;
}

.bg-or-2 {
  background-image: linear-gradient(
    270deg,
    rgba(195, 120, 54, 0.91) 0%,
    #ffb902 16%,
    #ffb902 100%
  );
}

.bg-green {
  background: #97bf58 !important;
}
.t-green {
  color: #97bf58 !important;
}

.bg-green-2 {
  background: #6d9773 !important;
}

.bg-green-3 {
  background: #c8ebd0 !important;
}

.bg-green-4 {
  background: $bg-7 !important;
}

.bg-gray {
  background: #ebebeb;
}
/* Login */

.login-side {
  background-image: url("./assets/img/login-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.login-card {
  margin-top: 30%;
}
input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 3;
}

// sidebar section

.real {
  display: flex;
}

.cont-div {
  // position: fixed;
}
.sidebar {
  flex: 1 1 0;
  margin: 0;
  padding: 2rem 1rem;
  max-width: 280px;
  background: #4b7d13;
  height: 100vh;

  overflow: auto;
  box-shadow: 8px 0px 19px -4px rgba(36, 71, 97, 0.75);

  a {
    display: block;
    color: white;
    text-decoration: none;

    font-size: 19px;
    padding: 20px 0 15px 40px;
  }

  a:hover {
    background: #f4f4f4;
    transition: 0.5s;
    // border-radius: 0 40px 40px 0;
    color: #97bf58 !important;
    // font-weight: bold;
  }
  .dash-icon {
    margin-right: 30px;
    font-size: 30px;
  }
  .logout-div {
    // margin-top: 150px;
    // bottom: 0;
    // top: 0;
    position: absolute;
    bottom: 5%;
    width: 100%;
    box-sizing: border-box;
  }
  .bottom-p {
    position: absolute;
    bottom: 5%;
    width: 100%;
    // box-sizing: border-box;
  }

  .admin-span {
    font-size: 15px;
  }
  .admin-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  .logout-btn {
    background-color: white;
    height: 25px;
    width: 65px;
    font-size: 10px;
    border-radius: 15px;
    margin-left: 40px;
    color: red;
  }
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
  .login-side {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .sidebar {
    width: 100%;
  }
}
// @media screen and (max-width: 1025px) {
//   .sidebar {
//     max-width: 200px;
//   }
// }
@media (min-width: 1025px) {
  #m-tog {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  // .sidebar-toggle {
  //   display: block;
  // }

  .content {
    padding-top: 8rem;
  }

  .sidebar {
    position: fixed;
    top: 0;
    // left: -280px;
    height: 100%;
    width: 100%;
    max-width: 280px;
    transition: 0.2s linear;
    z-index: 1;
    // position: relative;
  }
  .sidebar.show {
    // left: 0;
  }
}

@media (max-width: 375px) {
  // .sidebar {
  //   position: absolute;
  //   display: none;
  // }

  // .sidebar-toggle {
  //   display: block;
  // }

  // #m-tog {
  //   display: block;
  // }
}

.content {
  flex: 1 1 0;
  padding: 2rem;
  overflow-x: scroll;
  overflow-y: scroll;
  height: 100vh;
  width: 100%;

  // position: fixed;
}

.sidebar-toggle {
  position: fixed;
  top: 2rem;
  right: 2rem;
  width: 50px;
  height: 50px;
  border-radius: 99px;
  cursor: pointer;
  background: #4b7d13;
  z-index: 1;
}

.sidebar-toggle.show {
  transform: rotate(45deg);
}

.sidebar-toggle.show::before {
  top: 0;
  transform: rotate(0deg);
}

.sidebar-toggle.show::after {
  top: 0;
  transform: rotate(90deg);
}

.sidebar-icon {
  position: relative;
  top: calc(50% - 2px);
  left: 50%;
  transform: translate(-50%, -50px);
  width: 30px;
}

.logo-section {
  height: 90px;
  margin-bottom: 50px;
}

.active-m {
  background-color: whitesmoke;
  border-right: 7px solid white;
  color: #4b7d13 !important;
}

.user-icon {
  height: 40px;
  width: 40px;
  border: 2px solid $main-bg;
  position: relative;
  margin: 0 auto;
}

.user-card {
  border-radius: 0 0 25px 25px !important;
  width: 100%;
  border: 2px solid $main-bg;
  text-transform: capitalize;

  height: 340px;

  button {
    border-radius: 15px;
  }
}

.btn-create {
  background: #245d3c !important;
  border-radius: 25px !important;
  border: none !important;
}

.btn-cancel {
  background: $bg-3 !important;
  border-radius: 25px !important;
  border: none !important;
}

.btn-download {
  background: $bg-5 !important;
  border-radius: 25px !important;
  border: none !important;
}

.breadcrumb {
  background: none !important;
}

.form-search {
  width: 250px !important;
  border-radius: 50px !important;
  display: inline !important;
  position: absolute !important;
}

.nav-tabs {
  border-bottom: 2px solid $bg-2 !important;
}
.nav {
  cursor: pointer;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: $bg-2 !important;
}

.rdt_TableHead {
  text-transform: uppercase;
  font-weight: bold;
}
.m-drop {
  background: transparent !important;
}

.season-year {
  margin-left: 4rem;
}

table {
  font-size: 13px;
}

.farm-status {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.fa-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: $bg-4;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui > h1 {
  margin-top: 0;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

table th {
  text-transform: uppercase;
}

table td {
  text-transform: capitalize;
}

.border-r {
  border-right: 3px solid $bg-6;
}

.drop-down-circle {
  width: 40px;
}

.truncate {
  // width: [width];
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-item.active .page-link {
  background: $bg-4 !important;
  color: #f4f4f4 !important;
}

.season-card {
  .card-body {
    padding: 1rem !important;
  }
}
